'use client';

import { FC, useEffect } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import { Button } from 'modules/theme/components/button/Button';
import { Box } from '@mui/material';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { Svg } from 'modules/theme/components/svg/Svg';
import Image500 from 'components/page/error/svg/500.svg';
import PageView from 'components/analytics/PageView';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import { EGtmEvent } from 'modules/gtmEvents/enums/EGtmEvent';
import { ETrackEventCategory } from 'modules/gtmEvents/enums/ETrackEventCategory';
import { usePageViewEvent } from 'modules/gtmEvents/hooks/pageView/usePageViewEvent';


interface IProps {
    errorPageTitle: string;
    errorPageText: string;
    reloadButtonText: string;
    contactButtonText: string;
}

const Main: FC<IProps> = ({ errorPageTitle,  errorPageText, reloadButtonText, contactButtonText }) => {

    const { gtm: { sendEvent }} = useAnalytics();
    usePageViewEvent();

    const redirectToContactsPage = () => {
        window.location.href = `${AppConfigService.getNehnutelnostiUrl()}/kontakty`;
    };

    const reloadPage = () => {
        window.location.reload();
    };

    useEffect(() => {
        sendEvent({
            'event': EGtmEvent.TRACK_EVENT,
            'category': ETrackEventCategory.ERROR,
            'action': window.location.href,
        });
    }, [sendEvent]);

    // free wraped box rewrite next.js hmm... i dont know why
    return (
        <>
            <PageView/>
            <Box>
                <Box>
                    <Box>
                        <Box sx={{
                            maxWidth: 577,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                            mx: 'auto',
                        }}>
                            <Svg icon component={Image500} width={241} />
                            <Box>
                                <Text variant='h1' semibold center>{errorPageTitle}</Text>
                            </Box>
                            <Box>
                                <Text variant='body1' center color={palette => palette.labelSecondary}>{errorPageText}</Text>
                            </Box>
                            <Box mt={1} display={{ xs: 'block', md: 'none' }}>
                                <Button
                                    small
                                    text={reloadButtonText}
                                    onClick={reloadPage}
                                />
                            </Box>
                            <Box mt={1} display={{ xs: 'none', md: 'block' }}>
                                <Button
                                    text={reloadButtonText}
                                    onClick={reloadPage}
                                />
                            </Box>

                            <Box display={{ xs: 'block', md: 'none' }}>
                                <Button
                                    small
                                    text={<Box fontWeight={600}>{contactButtonText}</Box>}
                                    onClick={redirectToContactsPage}
                                    textBtn
                                />
                            </Box>
                            <Box display={{ xs: 'none', md: 'block' }}>
                                <Button
                                    text={<Box fontWeight={600}>{contactButtonText}</Box>}
                                    onClick={redirectToContactsPage}
                                    textBtn
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default Main;
